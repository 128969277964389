<template>
  <lazy-loyalty-rewards-preview-tile-basic v-if="$feature.tempHeaderVariation === 'basic'" v-bind="forwarded" />
  <lazy-loyalty-rewards-preview-tile v-else v-bind="forwarded" />
</template>

<script lang="ts" setup>
import { useForwardPropsEmits } from 'radix-vue'
import type { Props } from '#commerce/components/loyalty/RewardsPreviewTile.vue'

const props = defineProps<Props>()
const { $feature } = useNuxtApp()
const forwarded = useForwardPropsEmits(props)

const LazyLoyaltyRewardsPreviewTile = defineAsyncComponent(() =>
  import('#commerce/components/loyalty/RewardsPreviewTile.vue')
)

const { brandClasses } = useAppConfig().components.vf.loyalty

if ($feature.tempHeaderVariation === 'elevated')
  brandClasses.rewardsPreviewTileButton = 'px-3 py-2 text-xs vf-button-primary-inverse vf-button bg-grey-90 @hactive:bg-grey-70'
</script>
